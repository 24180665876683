@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@layer base {
  :root {
    --color-text-base: #fff;
    --color-text-muted: #bababa;
    --color-text-inverted: #121212;
    --color-text-muted-inverted: #575757;

    --color-background-base: #fff;
    --color-background-inverted: #000000;

    --color-button-base: #008080;
    --color-button-base-hover: #00b2b2;

    /* --color-button-base: #121212;
    --color-button-base-hover: #242424; */

    --color-button-base-inverted: #fff;
    --color-button-base-hover-inverted: #e4e4e4;

    --color-accent-base: #38A3A5;
    --color-accent-light: hsl(181, 49%, 83%);
  }
  theme-dark {
    --color-text-base: #fff;
    --color-text-muted: #79a4a5;
    --color-text-inverted: #49a4a5;
    --color-fill: #38A3A5;
    --color-button-accent: #fff;
    --color-button-accent-hover: #eef2ff;
    --color-button-muted: #499193;
  }
  theme-light {
    --color-text-base: #fff;
    --color-text-muted: #79a4a5;
    --color-text-inverted: #49a4a5;
    --color-fill: #38A3A5;
    --color-button-accent: #fff;
    --color-button-accent-hover: #eef2ff;
    --color-button-muted: #499193;
  }
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.pricing {
  background: url(./images/bg/pricing-wave.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: url(./images/bg/cta-wave.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}
